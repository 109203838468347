import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Image from "gatsby-plugin-sanity-image"

const IndexPage = ({ data }) => {
  // console.log(data, "data test")
  const sitepage = data.allSanityPage.nodes
  // console.log("dataHome", data.allSanityPage.nodes)

  return (
    <Layout>
      <div className="bg-texture px-6 lg:px-0 overflow-hidden">
        <div className="mt-12 mx-auto">
          <h1 className="text-center text-mocha font-display font-semibold text-5xl">
            Getting Started
          </h1>
        </div>

        <div className="mt-5 max-w-7xl mx-auto flex flex-wrap justify-center space-y-8 space-x-0 sm:space-y-12 sm:space-x-12">
          {sitepage.map(_page => (
            <div className="relative group w-full sm:w-2/5">
              <Link to={`/pages/${_page.slug.current}`}>
                <div className="h-96 sm:h-112">
                  <Image
                    {..._page.image}
                    alt={`${_page.name} Avatar`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="absolute bottom-0 w-full h-12 flex items-center justify-center bg-mocha group-hover:bg-mocha-light transition duration-300 ease-in-out">
                  <span className="text-center text-xl text-white font-display tracking-wide">
                    {_page.name}
                    {/* {console.log("page", _page)} */}
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query AllSitepages {
    allSanityPage {
      nodes {
        name
        flavorText {
          _key
          _type
          style
          list
          _rawChildren
        }
        slug {
          _key
          _type
          current
        }
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`
